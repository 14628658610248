import { FundAccount } from "providers/rest-data-provider/utils/interfaces";

export interface FundBalanceLog {
  fundBalanceLogId: number;
  fundAccount: FundAccount;
  transactionType: FUND_LOG_TRANSACTION_TYPE_ENUM;
  transactionId: number;
  action: FUND_LOG_ACTION_ENUM;
  description: string;
  user: string;
  previousAvailableBalance: number;
  newAvailableBalance: number;
  previousTotalValue: number;
  newTotalValue: number;
  amount: number;
  createdAt: Date;
}
export enum FUND_LOG_TRANSACTION_TYPE_ENUM {
  CONTRIBUTION = "CONTRIBUTION",
  INVESTMENT_BUY = "INVESTMENT_BUY",
  INVESTMENT_SELL = "INVESTMENT_SELL",
  INVESTMENT_DIVIDEND = "INVESTMENT_DIVIDEND",
  GRANT = "GRANT",
  GRANTVESTMENT = "GRANTVESTMENT",
  DISTRIBUTION = "DISTRIBUTION",
  FEE = "FEE",
}
export enum FUND_LOG_ACTION_ENUM {
  CREATE = "CREATE",
  CRON_JOB = "CRON_JOB",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  PROCESS = "PROCESS",
  REFUND = "REFUND",
}
