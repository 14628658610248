import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral, errorAlert, primary } from "style/color";
import { numberRange, shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";

import { ReactComponent as EyeIcon } from "./../../assets/icons/eye.svg";
import { ReactComponent as EyeClosedIcon } from "./../../assets/icons/eye-closed.svg";

import { useCustom } from "@refinedev/core";
import {
  FUND_MOVEMENT_STATUS_ENUM,
  FUND_MOVEMENT_TYPE_ENUM,
} from "interfaces/fund-movements-history";
import { IFundPortfolioStatistics } from "interfaces/fund-portfolio-statistics";
import { BalanceCard } from "./CustomCard";

interface MovementsCardProps {
  fundId: number;
}
export const MovementsCard: React.FC<MovementsCardProps> = ({ fundId }) => {
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const typesFilter = [
    { title: "Contributions", status: FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION },
    { title: "Grants", status: FUND_MOVEMENT_TYPE_ENUM.GRANT },
    { title: "Investments", status: FUND_MOVEMENT_TYPE_ENUM.INVESTMENT },
    { title: "GrantVestments", status: FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT },
    { title: "Distribution", status: FUND_MOVEMENT_TYPE_ENUM.DISTRIBUTION },
    { title: "Fee", status: FUND_MOVEMENT_TYPE_ENUM.FEE },
  ];
  const [types, setTypes] = React.useState<string[]>([
    FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION,
    FUND_MOVEMENT_TYPE_ENUM.GRANT,
    FUND_MOVEMENT_TYPE_ENUM.INVESTMENT,
    FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT,
    FUND_MOVEMENT_TYPE_ENUM.DISTRIBUTION,
    FUND_MOVEMENT_TYPE_ENUM.FEE,
  ]);
  const handleYearChange = (year: number) => {
    setYear(year);
  };

  const { data: paginatedData, isLoading } = useCustom({
    url: `fund/movements`,
    method: "get",
    config: {
      query: {
        fundId: fundId,
        offset: 0,
        ...{ "filter[types]": [...types] },
      },
    },
  });
  const { data: statisticsData, isLoading: statisticsLoading } =
    useCustom<IFundPortfolioStatistics>({
      url: `fund/movements/${fundId}`,
      method: "get",
      queryOptions: {
        enabled: !!fundId,
      },
    });

  const contains = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    return types.includes(type);
  };
  const handleTypeChange = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    setTypes((prev) => {
      const index = prev.findIndex((item) => item === type);
      let filterTypes = [];

      if (index !== -1) {
        filterTypes = [...prev];
        filterTypes.splice(index, 1);
      } else {
        filterTypes = [...prev, type];
      }
      return filterTypes;
    });
  };

  const movements = paginatedData?.data.data;
  const getStatus = (
    status: FUND_MOVEMENT_STATUS_ENUM,
    type: FUND_MOVEMENT_TYPE_ENUM
  ) => {
    switch (status) {
      case FUND_MOVEMENT_STATUS_ENUM.REJECTED:
        return "DECLINED";
      case FUND_MOVEMENT_STATUS_ENUM.ACCEPTED:
        return "APPROVED";
      case FUND_MOVEMENT_STATUS_ENUM.PENDING:
        return type === FUND_MOVEMENT_TYPE_ENUM.GRANT
          ? "PENDING"
          : "IN PROGRESS";
      case FUND_MOVEMENT_STATUS_ENUM.APPROVED:
        return type === FUND_MOVEMENT_TYPE_ENUM.GRANT
          ? "IN PROGRESS"
          : type === FUND_MOVEMENT_TYPE_ENUM.INVESTMENT
          ? "PROCESSED"
          : "APPROVED";
      default:
        return status;
    }
  };
  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>
        {statisticsLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid sx={{ mb: 2 }} container spacing={2}>
            <Grid item xs={12} sm={4}>
              {BalanceCard(
                "Cash",
                statisticsData?.data.myCashTotalValue as number
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {BalanceCard(
                "Investment",
                statisticsData?.data.myInvestmentsTotalValue as number
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {BalanceCard(
                "GrantVestment",
                statisticsData?.data.myGrantVestmentTotalValue as number
              )}
            </Grid>
          </Grid>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: `neutral.main` }} variant="subtitle3">
            Movements
          </Typography>

          <Select
            id="title-select"
            size="small"
            name="title"
            value={year}
            onChange={(event) => handleYearChange(+event.target.value)}
          >
            {numberRange(new Date().getFullYear(), 2023, -1).map((year) => (
              <MenuItem key={year} value={year}>
                {year === new Date().getFullYear() ? "Current year" : year}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Grid sx={{ my: 1 }} container spacing={2}>
          {typesFilter.map((item, index) => {
            return (
              <Grid key={index} item>
                {contains(item.status) ? (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeIcon />}
                    variant={"contained"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeClosedIcon />}
                    variant={"outlined"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                )}
              </Grid>
            );
          })}
        </Grid>
        {movements.length !== 0 ? (
          <List
            className="successor"
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              mt: 2,
              [` & .${listItemClasses.root}:hover`]: {
                backgroundColor: warm[200],
                cursor: "pointer",
              },
            }}
          >
            {movements.map((movement: any, index: number) => {
              const { status } = movement;
              return (
                <>
                  <ListItem key={index}>
                    <Grid alignItems="center" container>
                      <Grid item xs={12} sm={3} textAlign={"start"}>
                        <ListItemText
                          sx={{
                            display: "inline-grid",
                            width: "auto",
                            flex: "none",
                          }}
                          primary={
                            <Typography
                              sx={{ color: `neutral.main` }}
                              variant="bodyCopyBold"
                              gutterBottom
                            >
                              {movement.title}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              sx={{ color: neutral[600] }}
                              variant="smallCopyBold"
                            >{`By ${movement.user}`}</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} textAlign={"center"}>
                        <Typography
                          variant="bodyCopyRegular"
                          sx={{ color: neutral[600] }}
                        >
                          {shortFormat(movement.date)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} textAlign={"center"}>
                        <Typography
                          variant="bodyCopyRegular"
                          sx={{
                            color: neutral[900],
                            textTransform: "capitalize",
                          }}
                        >
                          {movement.type.toLowerCase()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2} textAlign={"center"}>
                        <Button
                          sx={{
                            borderRadius: "4px",
                            p: 1,
                            backgroundColor: warm[200],
                            color:
                              getStatus(status, movement.type) === "IN PROGRESS"
                                ? neutral[500]
                                : status ===
                                    FUND_MOVEMENT_STATUS_ENUM.REJECTED ||
                                  status === FUND_MOVEMENT_STATUS_ENUM.CANCELED
                                ? errorAlert[500]
                                : primary[500],
                            paddingX: 2,
                            paddingY: 1.5,
                            fontFamily: "Quattrocento Sans",
                            fontSize: "12px",
                            fontWeight: "700",
                            lineHeight: "13.3px",
                          }}
                        >
                          {getStatus(status, movement.type)}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={2} textAlign={"end"}>
                        <Typography
                          sx={{
                            px: 1,
                            py: 1,
                            color:
                              status !== FUND_MOVEMENT_STATUS_ENUM.PENDING
                                ? neutral[900]
                                : neutral[500],
                            backgroundColor:
                              movement.type ===
                              FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION
                                ? primary[50]
                                : "transparent",
                            textDecoration:
                              status === FUND_MOVEMENT_STATUS_ENUM.REJECTED ||
                              status === FUND_MOVEMENT_STATUS_ENUM.CANCELED
                                ? "line-through"
                                : "none",
                          }}
                          variant="bodyCopyBold"
                          gutterBottom
                        >
                          <>
                            {(movement.type === FUND_MOVEMENT_TYPE_ENUM.FEE ||
                              movement.type === FUND_MOVEMENT_TYPE_ENUM.GRANT ||
                              movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT ||
                              (movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.INVESTMENT &&
                                movement.transactionType === "BUY")) &&
                              "-"}
                            ${moneyFormat(movement.amount)}
                          </>
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                  {index !== movements.length - 1 && (
                    <Divider key={index + 100} />
                  )}
                </>
              );
            })}
          </List>
        ) : (
          <Typography
            sx={{ mt: 4, color: neutral[600] }}
            variant="bodyCopyRegular"
          >
            You don't have any past transactions.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
