import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { IResourceComponentsProps, useCustom, useShow, useUpdate } from "@refinedev/core";
import { FundGroup, IFundAccount, Tiers } from "interfaces/fund-account";
import { useState } from "react";
import { neutral, warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ICategory } from "interfaces/category";
import {
  CharityInfo,
  IndividualInfo,
  SUCCESSOR_TYPE_ENUM,
  ISuccessor,
} from "interfaces/successor";
import { MovementsCard } from "components/card/movementsCard";
import { CustomTabPanel } from "components/panel/CustomTabPanel";
import { useNavigate } from "react-router-dom";
import { TiersTable } from "components/table/tiersTable";
import { AddToGroupModal } from "components/modal/add-to-group-modal";

export const FundAccountDetails: React.FC<IResourceComponentsProps> = () => {
  const { queryResult, } = useShow<IFundAccount>();
  const { data, refetch } = queryResult;
  const [value, setValue] = useState(0);
  const [reset, setReset] = useState(false);
  const [openAddToGroup, setAddToGroup] = useState(false);
  const [tiers, setTiers] = useState({});
  const { mutate, isLoading } = useUpdate();
  const { data: successorsData, isLoading: successorsLoading } = useCustom<
    ISuccessor[]
  >({
    url: `funds/${queryResult.data?.data.fundAccountId}/successors`,
    method: "get",

    queryOptions: {
      enabled: !!queryResult.data?.data.fundAccountId,
    },
  });
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const changeTiers = (key: string, newValue: Tiers[]) => {
    setTiers({
      ...tiers,
      [key]: newValue
    });
  };
  const isLinked = (group: FundGroup, id: number) => {
    if (group.funds.length === 1 && group.funds[0].fundAccountID === id) {
      return false
    }
    return true
  };
  const submit = () => {
    mutate({
      resource: 'fund-group',
      values: tiers,
      id: `${data?.data.group.fundGroupId}`
    }, {
      onSuccess: () => {
      }
    })
  };
  const remove = () => {
    mutate({
      resource: 'funds',
      values: {},
      id: `${data?.data.fundAccountId}/remove-group`
    }, {
      onSuccess: () => {
        refetch()
      }
    })
  };
  return (
    <>
      {queryResult?.status === "loading" ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Grid sx={{ backgroundColor: warm[50], my: 4 }}>
          <Box sx={{ mx: 3 }}>
            <Box sx={{ mx: 3 }}>
              <Grid sx={{ mb: 2, mt: 2 }} container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h3">
                    {data?.data.fundAccountName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    p: 2,
                    pt: "37px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    my={2}
                    textAlign={"right"}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    <Button variant="contained" color="primary" size="large">
                      {data?.data.entityInformation ? "Entity" : "Personal"}
                    </Button>
                  </Grid>
                  <Grid
                    sm={4}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: 4,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="smallCopy" color="primary">
                          TOTAL BALANCE
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.94px",
                          }}
                        >
                          {" "}
                          ${moneyFormat(data?.data.total!)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="smallCopy" color="primary">
                          AVAILABLE BALANCE
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.94px",
                          }}
                        >
                          ${moneyFormat(data?.data.availableBalance!)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Information" id={"information-tab"} />
                <Tab label="Users" id={"users-tab"} />
                <Tab label="Movements" id={"movement-tab"} />
                <Tab label="Tiers" id={"tiers-tab"} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box>
                {data?.data.entityInformation?.entityName && (
                  <>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={"primary"}
                    >
                      Entity information:
                    </Typography>
                    <TextField
                      value={
                        data?.data.entityInformation.entityName &&
                        data?.data.entityInformation.entityName
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity name"
                      name="entityInformation.entityName"
                      disabled
                    />
                    <TextField
                      value={
                        data?.data.entityInformation.type &&
                        data?.data.entityInformation.type
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity type"
                      disabled
                    />

                    <TextField
                      value={
                        data?.data.entityInformation.firstAddressLine &&
                        data?.data.entityInformation.firstAddressLine
                      }
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Entity Address "
                      disabled
                    />

                    <TextField
                      value={data?.data?.entityInformation.city}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="City"
                      disabled
                    />
                    <TextField
                      value={data?.data?.entityInformation.zip}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Zip"
                      disabled
                    />
                    <TextField
                      value={data?.data.entityInformation?.state}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="State"
                      disabled
                    />
                    {data?.data?.entityInformation.secondAddressLine && (
                      <TextField
                        value={data?.data?.entityInformation.secondAddressLine}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Entity second Address "
                        disabled
                      />
                    )}
                  </>
                )}
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={"primary"}
                  sx={{ my: 1 }}
                >
                  Fund Interest:
                </Typography>
                {data?.data.interests && data?.data.interests.length > 0 ? (
                  <Grid sx={{ display: "flex" }} gap={1}>
                    {data?.data.interests?.map((category: ICategory) => (
                      <Grid item>
                        <Button
                          variant="outlined"
                          size="large"
                          color={"primary"}
                        >
                          {category.categoryName}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="smallCopy">
                    No interest found.
                  </Typography>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color={"primary"}
                pb={2}
              >
                Fund Account Users:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {data?.data.fundAccountMembers &&
                  data?.data.fundAccountMembers?.length > 0 ? (
                  data?.data.fundAccountMembers?.map((fundAccountMember) => (
                    <Card
                      sx={{ backgroundColor: warm[200], boxShadow: "none" }}
                      onClick={() =>
                        navigate(`/users/show/${fundAccountMember.user.sub}`)
                      }
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Typography
                              variant="subtitle3"
                              sx={{
                                color: neutral[700],
                                display: "flex",
                                alignItems: "center",
                              }}
                              gutterBottom
                            >
                              <Box sx={{ mr: 3 }}>{`${fundAccountMember?.user.title
                                ? fundAccountMember.user?.title
                                : ""
                                } ${fundAccountMember.user?.firstName
                                  ? fundAccountMember.user?.firstName
                                  : ""
                                } ${fundAccountMember.user?.middleName
                                  ? fundAccountMember.user?.middleName
                                  : ""
                                } ${fundAccountMember.user?.lastName
                                  ? fundAccountMember.user?.lastName
                                  : ""
                                } ${fundAccountMember.user?.suffix
                                  ? fundAccountMember.user?.suffix
                                  : ""
                                }`}</Box>
                            </Typography>
                            <Typography
                              variant="bodyCopyBold"
                              component={"div"}
                            >
                              {fundAccountMember.user.email}
                            </Typography>
                            <Typography
                              variant="bodyCopyRegular"
                              component={"div"}
                            >
                              {fundAccountMember.user.phoneNumber}
                            </Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No user found.{" "}
                  </Typography>
                )}
              </Box>

              <Typography
                variant="body1"
                fontWeight="bold"
                py={2}
                color={"primary"}
              >
                Fund Account Successors:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {successorsLoading && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", my: 5 }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
                {!successorsLoading &&
                  successorsData?.data &&
                  successorsData?.data.length > 0 ? (
                  successorsData?.data.map((successor) => (
                    <Card
                      sx={{
                        backgroundColor: warm[200],
                        boxShadow: "none",
                        minWidth: "400px",
                      }}
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="column">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ alignSelf: "flex-end" }}
                          >
                            {successor.percentage}%
                          </Button>
                          <Stack direction="row" spacing={2}>
                            <Box>
                              <Typography
                                variant="subtitle3"
                                sx={{
                                  color: neutral[700],
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                gutterBottom
                              >
                                <Box sx={{ mr: 3 }}>
                                  {successor.successorType ===
                                    SUCCESSOR_TYPE_ENUM.INDIVIDUAL
                                    ? `${(
                                      successor.successorInfo as IndividualInfo
                                    ).title
                                      ? (
                                        successor.successorInfo as IndividualInfo
                                      ).title
                                      : ""
                                    } ${(
                                      successor.successorInfo as IndividualInfo
                                    ).firstName
                                      ? (
                                        successor.successorInfo as IndividualInfo
                                      ).firstName
                                      : ""
                                    } ${(
                                      successor.successorInfo as IndividualInfo
                                    ).middleName
                                      ? (
                                        successor.successorInfo as IndividualInfo
                                      ).middleName
                                      : ""
                                    } ${(
                                      successor.successorInfo as IndividualInfo
                                    ).lastName
                                      ? (
                                        successor.successorInfo as IndividualInfo
                                      ).lastName
                                      : ""
                                    } ${(
                                      successor.successorInfo as IndividualInfo
                                    ).suffix
                                      ? (
                                        successor.successorInfo as IndividualInfo
                                      ).suffix
                                      : ""
                                    }`
                                    : (successor.successorInfo as CharityInfo)
                                      .name}
                                </Box>
                              </Typography>
                              {successor.successorType ===
                                SUCCESSOR_TYPE_ENUM.INDIVIDUAL ? (
                                <>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).email
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (
                                        successor.successorInfo as IndividualInfo
                                      ).phoneNumber
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {` ${(
                                      successor.successorInfo as IndividualInfo
                                    ).addressLine1
                                      }, ${(
                                        successor.successorInfo as IndividualInfo
                                      ).addressLine2
                                      }, ${(
                                        successor.successorInfo as IndividualInfo
                                      ).city
                                      }, ${(
                                        successor.successorInfo as IndividualInfo
                                      ).state
                                      }, ${(
                                        successor.successorInfo as IndividualInfo
                                      ).zip
                                      }`}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (successor.successorInfo as CharityInfo)
                                        .ein
                                    }
                                  </Typography>
                                  <Typography
                                    variant="bodyCopyBold"
                                    component={"div"}
                                  >
                                    {
                                      (successor.successorInfo as CharityInfo)
                                        .specificInstruction
                                    }
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No Succession plan found.{" "}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                py={2}
                color={"primary"}
              >
                Fund Account Invitations:{" "}
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                {data?.data.invitations &&
                  data?.data.invitations?.length > 0 ? (
                  data?.data.invitations?.map((invitation) => (
                    <Card
                      sx={{ backgroundColor: warm[200], boxShadow: "none" }}
                    >
                      <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Typography
                              variant="bodyCopyBold"
                              sx={{
                                color: neutral[700],
                                display: "flex",
                                alignItems: "center",
                              }}
                              gutterBottom
                            >
                              <Box sx={{ mr: 3 }}>{invitation?.status}</Box>
                            </Typography>
                            <Typography variant="subtitle3">
                              {invitation.email}
                            </Typography>
                            <Typography
                              variant="bodyCopyRegular"
                              component={"div"}
                            >
                              Expired At:{invitation.expiredAt}
                            </Typography>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography
                    sx={{ color: neutral[600] }}
                    variant="bodyCopyRegular"
                  >
                    No invitations found.{" "}
                  </Typography>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <MovementsCard fundId={data?.data.fundAccountId as number} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Grid container spacing={2}>


                {data?.data.group && <Grid item xs={12} lg={12}>
                  {isLinked(data?.data.group!, data?.data.fundAccountId!) ?
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                      <List
                      >
                        <ListItem>
                          <ListItemText
                            primary={`This fund is associated with`}
                          />
                        </ListItem>
                        {data?.data.group.funds.filter(item => item.fundAccountID !== data.data.fundAccountId).map((fund) => {
                          return (
                            <ListItem key={fund.fundAccountID}>
                              <ListItemIcon>
                                <NavigateNextIcon />
                              </ListItemIcon>
                              <ListItemText primary={fund.fundAccountName as string} />
                            </ListItem>
                          );
                        })}
                        <ListItemText
                          secondary={`This tiers are applied for all the funds above`}
                        />
                      </List>
                      <Button color='primary' variant='contained' onClick={remove} >Remove from group</Button>
                    </Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography
                        sx={{ color: neutral[600] }}
                        variant="bodyCopyRegular"
                      >
                        This fund is not associated to others accounts
                      </Typography>
                      <Button color='primary' variant='contained' onClick={() => setAddToGroup(true)}>Add to group</Button>
                      <AddToGroupModal open={openAddToGroup} setOpen={setAddToGroup} fund={data.data} refetch={refetch} />
                    </Box>


                  }
                </Grid>}

                <Grid item xs={12} lg={6}>

                  <TiersTable reset={reset} changeTiers={changeTiers} tiers={data?.data.group.aumTiers!} title="AUM"></TiersTable>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TiersTable reset={reset} changeTiers={changeTiers} tiers={data?.data.group.gvTiers!} title="GV"></TiersTable>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button sx={{ mr: 2 }} variant='outlined' color='primary' onClick={() => setReset(prev => { return !prev })}  >Discard</Button>
                  <Button variant='contained' color='primary' onClick={submit} disabled={isLoading}
                    startIcon={
                      isLoading && <CircularProgress size={20} color="inherit" />
                    } >Save</Button>
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </Grid>
      )}
    </>
  );
};
